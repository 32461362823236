<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbItems" />
    <!-- start layout detail service in header page -->
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex m-2">
              <div>
                <h5 style="align-self: flex-start; font-weight: 600">
                  {{ form.user_code }} - {{ form.company_name }}
                </h5>
              </div>
              <div
                class="text-sm-end"
                style="margin-left: auto; margin-top: 10px; margin-right: 10px"
              >
                <button
                  type="button"
                  class="btn btn-primary me-2 custom-btn"
                  @click="showEditDialog"
                >
                  <em class="mdi me-1" /> 編集
                </button>
              </div>
            </div>
            <div class="media">
              <div class="me-3 SaaSApp__logo">
                <img
                  v-if="form.company_logo"
                  class="image-detail"
                  :src="form.company_logo"
                  alt="image"
                />
                <img v-else class="image-detail" :src="defaultCompanyLogo" alt="default-imagé" />
              </div>
              <div class="media-body col-sm-3 align-self-center">
                <div class="text-muted">
                  <p class="text-dark font-size-12 font-custom">
                    ドメイン：
                    <span class="font-detail">{{ form.company_domain }}</span>
                  </p>
                  <p class="text-dark font-size-12 font-custom">
                    メールアドレス：
                    <span class="font-detail">{{ form.email }}</span>
                  </p>
                  <p class="text-dark font-size-12 font-custom">
                    電話番号：
                    <span class="font-detail">{{ form.company_phone }}</span>
                  </p>
                  <p class="text-dark font-size-12 font-custom">
                    業界：
                    <span class="font-detail">{{ form.company_career_name.name }}</span>
                  </p>
                  <p class="text-dark font-size-12 font-custom">
                    資本金：
                    <span class="font-detail">{{ form.company_capital }}</span>
                  </p>
                </div>
              </div>
              <div class="media-body align-self-center">
                <div class="text-muted margin-custom">
                  <p class="text-dark font-size-12 font-custom font-custom">
                    利用中のサービス数：
                    <span class="font-detail">{{ listUsedAppByCompany.length }}</span>
                  </p>
                  <p class="text-dark font-size-12 font-custom">
                    従業員数：
                    <span class="font-detail">{{ form.company_count_member }}</span>
                  </p>
                  <p class="text-dark font-size-12 font-custom">
                    プラン：
                    <span class="font-detail">{{ form.company_package_name }}</span>
                  </p>
                  <p class="text-dark font-size-12 font-custom">
                    契約開始日：
                    <span class="font-detail">{{ getDateLabel(form.company_start_date) }}</span>
                  </p>
                  <p class="text-dark font-size-12 font-custom">
                    契約終了日：
                    <span class="font-detail">{{ getDateLabel(form.company_end_date) }}</span>
                  </p>
                </div>
              </div>
              <div class="media-body">
                <div class="text-muted">
                  <p class="text-dark font-size-12 font-custom">
                    ステータス:
                    <span class="font-detail" :class="getClassStatus(form.company_status)">{{
                      getStatusLabel(form.company_status)
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body">
            <div>
              <h5>利用中のサービス</h5>
              <h3 class="number text-center">
                <span class="text-primary">{{ listUsedAppByCompany.length }}</span>
                <span class="text-dark">/{{ totalApp }}</span>
              </h3>
            </div>
            <div class="d-flex flex-wrap justify-content-start card-detail">
              <div v-for="items in listUsedAppByCompany" :key="items.subscribe_app_id">
                <div class="card text-center SaaSApp">
                  <div class="SaaSApp__img">
                    <img :src="items.logo" alt="logo-image" />
                  </div>
                  <div class="SaaSApp__name">
                    <h5>{{ items.name }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-5">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <h5>サービス利用中のアカウント数</h5>
              <h3 class="number text-primary text-center">{{ totalAccount }}</h3>
            </div>
            <div>
              <apexchart
                class="apex-charts chart-class"
                height="360"
                type="pie"
                dir="ltr"
                :series="pieChart.series"
                :options="pieChart.chartOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end layout detail service-->
    <c-modal :id="dialogId" :title="title" :buttons="dialogButtons" size="xl">
      <management-member-modal-form
        :form-data.sync="formData"
        :trigger-validate="triggerValidate"
        :is-valid.sync="isValid"
      />
    </c-modal>
    <change-password-dialog
      :id="dialogChangePasswordId"
      :confirm-change-password="onChangePassword"
      :require-old-password="false"
    >
    </change-password-dialog>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main/app'
import PageHeader from '../../layouts/vertical/page-header'
import { managementMemberService } from '@/services/management-member-service'
import ManagementMemberModalForm from '@/components/forms/management-member-modal-form'
import CModal from '@/components/commons/common-modal'
import pick from 'lodash-es/pick'
import { stringToDateMonthJapan } from '@/utils/date-utils'
import { mapActions, mapGetters } from 'vuex'
import { ALERT_TYPE, COMPANY_STATUS, DEFAULT_COMPANY_LOGO, USING_STATUS } from '@/utils/constants'
import { MESSAGES } from '@/utils/messages'
import ChangePasswordDialog from '@/components/modals/change-password-modal'
import get from 'lodash-es/get'
import { isHttpLink } from '@/utils/object-helpers'
export default {
  components: {
    ManagementMemberModalForm,
    PageHeader,
    Layout,
    CModal,
    ChangePasswordDialog,
  },
  data() {
    return {
      defaultCompanyLogo: DEFAULT_COMPANY_LOGO,
      triggerValidate: false,
      isValid: false,
      requiredEditKeys: [
        'id',
        'company_name',
        'company_career',
        'company_phone',
        'company_domain',
        'email',
        'password',
        'company_package_id',
        'company_capital',
        'company_start_date',
        'company_end_date',
        'role_id',
        'company_status',
        'company_logo',
      ],
      pieChart: {
        series: [],
        chartOptions: {
          labels: [],
          colors: ['#556EE6', '#F46A6A', '#F1B44C', '#50A5F1', '#343A40', '#74788D', '#34C38F'],
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center',
            verticalAlign: 'middle',
            floating: false,
            fontSize: '14px',
            offsetX: 0,
          },
          responsive: [
            {
              breakpoint: 600,
              options: {
                chart: {
                  height: 240,
                },
                legend: {
                  show: false,
                },
              },
            },
          ],
        },
      },
      onAddOrEditConfirm: () => {
        return {}
      },
      listUseApp: [],
      dataPieChart: [],
      totalAccount: 0,
      filter: null,
      title: '顧客情報詳細',
      dialogId: 'show',
      dialogChangePasswordId: 'change-password-id',
      id: null,
      formData: [],
      form: {
        company_status: '',
        company_career_name: {
          name: null,
        },
      },
      memberData: null,
    }
  },
  computed: {
    ...mapGetters('commonSettings', ['user_status']),
    dialogButtons() {
      return [
        {
          title: 'キャンセル',
          variant: 'light',
          minWidth: 74,
          size: 'md',
        },
        {
          title: 'パスワード変更',
          variant: 'secondary',
          minWidth: 133,
          size: 'md',
          func: this.showChangePasswordDialog,
        },
        {
          title: '更新',
          variant: 'primary',
          minWidth: 133,
          size: 'md',
          func: this.onAddOrEditConfirm,
        },
      ]
    },
    breadcrumbItems() {
      return [
        {
          text: '顧客管理者',
          href: '/management-member',
        },
        {
          text: this.form.company_name,
          active: true,
        },
      ]
    },
    listUsedAppByCompany() {
      if (this.listUseApp.length > 0) {
        return this.listUseApp.filter((item) => item.subscribe_app_status === USING_STATUS.USE)
      }
      return []
    },
    totalApp() {
      return this.listUseApp.length
    },
  },
  async created() {
    this.id = parseInt(this.$route.params.id)
    await this.formReload()
  },
  methods: {
    ...mapActions('alert', ['displayAlert']),
    ...mapActions('loading', ['showManualLoading', 'hideManualLoading']),
    async formReload(isMaster = false) {
      this.showManualLoading()
      this.pieChart.series = []
      this.pieChart.chartOptions.labels = []
      this.form = await managementMemberService.getCustomerInfo(this.id, isMaster)
      this.convertValue()
      this.listUseApp = await managementMemberService.getUseAppAccount(this.form.company_id)
      this.dataPieChart = await managementMemberService.getAppAccount(this.form.company_id)
      this.totalAccount = this.dataPieChart.total
      this.dataPieChart.data.forEach((items) => {
        this.pieChart.series.push(items.users)
        this.pieChart.chartOptions.labels.push(items.app_name)
      })
      this.hideManualLoading()
    },
    showEditDialog() {
      this.dialogTitle = this.title
      this.formData = { ...this.form }
      this.onAddOrEditConfirm = async () => {
        this.triggerValidate = !this.triggerValidate
        setTimeout(async () => {
          if (this.isValid) {
            await this.onUpdateConfirm()
          }
        }, 150)
      }
      this.includeFields = []
      this.$bvModal.show(this.dialogId)
    },
    async onUpdateConfirm() {
      const formData = pick(this.formData, this.requiredEditKeys)
      if (isHttpLink(formData.company_logo)) {
        delete formData.company_logo
      }
      const { success, data } = await managementMemberService.editItem(formData)
      if (!success) {
        this.showAlertError(data)
        return
      }
      this.$bvModal.hide(this.dialogId)
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG02,
      })
      await this.formReload(true)
    },
    getClassStatus(status) {
      if (status === COMPANY_STATUS.ACTIVE) {
        return 'MTStatus MTStatus__active'
      }
      return 'MTStatus MTStatus__inactive'
    },
    getStatusLabel(status) {
      return get(this.user_status.filter((data) => data.id === status)[0], 'name', '')
    },
    getDateLabel(date) {
      if (date) {
        return stringToDateMonthJapan(date)
      }
    },
    convertDate(date) {
      const d = new Date(Date.parse(date))
      const lang = 'en'
      const year = d.toLocaleString(lang, { year: 'numeric' })
      let month = d.toLocaleString(lang, { month: 'numeric' })
      if (month.length === 1) {
        month = '0' + month
      }
      return `${year}-${month}`
    },
    convertValue() {
      if (this.form.company_start_date) {
        this.form.company_start_date = this.convertDate(this.form.company_start_date)
      }
      if (this.form.company_end_date) {
        this.form.company_end_date = this.convertDate(this.form.company_end_date)
      }
    },
    showChangePasswordDialog() {
      this.$bvModal.show(this.dialogChangePasswordId)
    },
    async onChangePassword(formData) {
      const { success } = await managementMemberService.changePassword(formData, {
        id: this.formData.id,
      })
      if (!success) {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: MESSAGES.COMMON.MSG15,
        })
        return
      }
      this.displayAlert({
        type: ALERT_TYPE.SUCCESS,
        messages: MESSAGES.COMMON.MSG01,
      })
      this.$bvModal.hide(this.dialogChangePasswordId)
    },
    showAlertError(messages) {
      const errorMessages = this.getErrorMessage(messages)
      errorMessages.forEach((mes) => {
        this.displayAlert({
          type: ALERT_TYPE.ERROR,
          messages: mes,
        })
      })
    },
    getErrorMessage(messages) {
      const result = []
      if (messages.email) {
        result.push(MESSAGES.ADMIN_MT.ADM04)
      }
      if (messages.company_name) {
        result.push(MESSAGES.ADMIN_MT.ADM02)
      }
      if (messages.company_domain) {
        result.push(MESSAGES.ADMIN_MT.ADM03)
      }
      if (messages.package) {
        result.push(MESSAGES.ADMIN_MT.ADM11)
      }
      if (result.length === 0) {
        result.push(MESSAGES.COMMON.MSG15)
      }
      return result
    },
  },
}
</script>

<style lang="scss">
.card-detail {
  height: 360px;
  overflow: auto;
}
.card-detail::-webkit-scrollbar {
  display: none;
}
.chart-class {
  height: 360px;
}
.SaaSApp {
  margin: 15px 20px;
  border-radius: 30px;
  border: 1px solid #a6b0cf;
  height: 130px;
  width: 130px;
  display: flex;
  justify-content: center;
  &__img {
    img {
      width: 70px;
      height: 70px;
    }
  }
  &__name {
    margin-top: 10px;
  }
  &__logo {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
    }
  }
}
.custom-btn {
  padding-bottom: 9px;
  border-radius: 10px !important;
  min-width: 90px;
}
.margin-custom {
  margin-left: 50px;
}
.font-custom {
  font-weight: 600;
}
.font-detail {
  font-weight: 400;
}
</style>
